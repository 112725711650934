//window.addEventListener('load', function () {





// obtain iframemanager object
var manager = iframemanager();

// obtain cookieconsent plugin
var cc = initCookieConsent();

// Configure with youtube embed
manager.run({
    currLang: 'cs',
    services: {
        youtube: {
            embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
            thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',
            iframe: {
                allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
            },
            cookie: {
                name: 'cc_youtube'
            },
            languages: {
                en: {
                    notice: 'This content is hosted by a third party. By showing the external content you accept the <a rel="noreferrer" href="https://www.youtube.com/t/terms" title="Terms and conditions" target="_blank" rel="noopener noindex nofollow noreferrer">terms and conditions</a> of&nbsp;youtube.com.',
                    loadBtn: 'Load video',
                    loadAllBtn: 'Don\'t ask again'
                },
                cs: {
                    notice: 'Tento obsah je hostován třetí stranou. Zobrazením externího obsahu přijímáte <a href="https://www.youtube.com/t/terms" title="Smluvní podmínky" target="_blank" rel="noopener noindex nofollow noreferrer">smluvní podmínky</a> z&nbsp;youtube.com.',
                    loadBtn: 'Načíst video',
                    loadAllBtn: 'Znovu se neptejte'
                }
            }
        },
        googleMaps: {
            embedUrl: 'https://www.google.com/maps/embed?pb={data-id}',
            // thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',
            // thumbnailUrl: '{data-thumbnail}',
            thumbnailUrl: 'assets/image/world-map.svg',
            iframe: {
                allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
            },
            cookie: {
                name: 'cc_googleMap'
            },
            languages: {
                en: {
                    notice: 'This content is hosted by a third party. By showing the external content you accept the <a href="https://www.google.com/intl/cs_cz/help/terms_maps/" title="Terms and conditions" target="_blank" rel="noopener noindex nofollow noreferrer">terms and conditions</a> of&nbsp;google.com.',
                    loadBtn: 'Load map',
                    loadAllBtn: 'Don\'t ask again'
                },
                cs: {
                    notice: 'Tento obsah je hostován třetí stranou. Zobrazením externího obsahu přijímáte <a href="https://www.google.com/intl/cs_cz/help/terms_maps/" title="Smluvní podmínky" target="_blank" rel="noopener noindex nofollow noreferrer">smluvní podmínky</a> z&nbsp;google.com.',
                    loadBtn: 'Načíst mapu',
                    loadAllBtn: 'Znovu se neptejte'
                }
            }
        }
    }
});

// run plugin with config object
cc.run({
    current_lang: 'cs',
    auto_language: 'document',                  // default: null; could also be 'browser' or 'document'
    autoclear_cookies: true,                    // default: false
    cookie_expiration: 365,                     // default: 182
    page_scripts: true,                         // default: false
    force_consent: true,                        // default: false
    // cookie_name: 'cc_cookie_demo2',          // default: 'cc_cookie'
    // autorun: true,                           // default: true
    // delay: 0,                                // default: 0
    // hide_from_bots: false,                   // default: false
    // remove_cookie_tables: false              // default: false
    // cookie_domain: location.hostname,        // default: current domain
    // cookie_path: '/',                        // default: root
    // cookie_same_site: 'Lax',
    // use_rfc_cookie: false,                   // default: false
    // revision: 0,                             // default: 0

    gui_options: {
        consent_modal: {
            layout: 'cloud',                    // box,cloud,bar
            position: 'bottom center',          // bottom,middle,top + left,right,center
            transition: 'slide'                 // zoom,slide
        },
        settings_modal: {
            layout: 'box',                      // box,bar
            position: 'left',                   // right,left (available only if bar layout selected)
            transition: 'slide'                 // zoom,slide
        }
    },

    onFirstAction: function () {
        // console.log('onFirstAction fired');
    },

    onAccept: function () {
        // console.log('onAccept fired!')

        // If analytics category is disabled => load all iframes automatically
        if (cc.allowedCategory('analytics')) {
            // console.log('iframemanager: loading all iframes');
            manager.acceptService('all');
        }





        // Custom method
        // analyticsChanged();




        /*if (cc.allowedCategory('necessary')){
            var dataLayer = window.dataLayer || [];
            dataLayer.push({
            event:"CookieConsent",
            consentType:"necessary"
          });
        }

        if (cc.allowedCategory('tracking')){
            var dataLayer = window.dataLayer || [];
            dataLayer.push({
            event:"CookieConsent",
            consentType:"tracking"
          });
        }

        if (cc.allowedCategory('performance')){
            var dataLayer = window.dataLayer || [];
            dataLayer.push({
            event:"CookieConsent",
            consentType:"performance"
          });
        }*/

    },

    onChange: function (cookie, changed_preferences) {
        // console.log('onChange fired!');

        // If analytics category is disabled => disable google analytics
        /*if (!cc.allowedCategory('analytics')) {
            typeof gtag === 'function' && gtag('consent', 'update', {
                'analytics_storage': 'denied'
            });
        }*/

        // If analytics category is disabled => ask for permission to load iframes
        if (!cc.allowedCategory('analytics')) {
            // console.log('iframemanager: disabling all iframes');
            manager.rejectService('all');
        } else {
            // console.log('iframemanager: loading all iframes');
            manager.acceptService('all');
        }






        // Custom method
        // analyticsChanged();





    },

    languages: {
        'en': {
            consent_modal: {
                title: 'Hello traveller, it\'s cookie time!',
                description: 'Our website uses essential cookies to&nbsp;ensure its proper operation and tracking cookies to&nbsp;understand how you interact with it. The latter will be set only after consent. <a href="zasady-ochrany-osobnich-udaju.html" class="cc-link">Privacy policy</a>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'      //'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Preferences',
                    role: 'settings'       //'settings' or 'accept_necessary'
                },
                revision_message: '<br><br> Dear user, terms and&nbsp;conditions have changed since the&nbsp;last time you visisted!'
            },
            settings_modal: {
                title: 'Cookie settings',
                save_settings_btn: 'Save current selection',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    { col1: 'Name' },
                    { col2: 'Domain' },
                    { col3: 'Description' },
                    //{col4: 'Expiration'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage',
                        description: 'I use cookies to&nbsp;ensure the&nbsp;basic functionalities of&nbsp;the&nbsp;website and&nbsp;to&nbsp;enhance your online experience. You can choose for&nbsp;each category to&nbsp;opt-in/out whenever you want. For more details relative to&nbsp;cookies and&nbsp;other sensitive data, please read the&nbsp;full <a href="#" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for&nbsp;the&nbsp;proper functioning of&nbsp;our&nbsp;website. Without these cookies, the website would not&nbsp;work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true  //cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            {
                                col1: 'cc_cookie',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Your cookie settings from this dialog'
                            }
                        ]
                    }, {
                        title: 'Analytics & Performance cookies',
                        description: 'These cookies allow the&nbsp;website to&nbsp;remember the&nbsp;choices you have made in&nbsp;the&nbsp;past',
                        toggle: {
                            value: 'analytics', // there are no default categories => you specify them
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'cc_youtube',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Cookie set by iframemanager'
                            },
                            {
                                col1: 'cc_googleMap',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Cookie set by iframemanager'
                            },
                            {
                                col1: '_pk_ses.7.c684',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Matomo Analytics - temporarily store data for&nbsp;the&nbsp;visit',
                            },
                            {
                                col1: '_pk_id.7.c684',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Matomo Analytics - the unique ID',
                            }/*,
                            {
                                col1: '_ga/_ga*, _gid',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Google Analytics - Stores and counts pageviews and site behavior',
                            },
                            {
                                col1: '_gcl_au',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Google Tag Manager - Conversion Linking',
                            },
                            {
                                col1: 'permId, p##*, pc##*',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Incomaker - Stores and counts page views and conversions',
                            },
                            {
                                col1: '_my_cookie',
                                col2: 'swo.vavyskov.cz',
                                col3: 'test cookie with custom path ...',
                                path: '/demo'       // needed for autoclear cookies
                            }*/
                        ]
                    }/*, {
                        title: "Marketing cookies",
                        description: "They are used to track a user's site preferences in order to target the ad, ie to display marketing and advertising messages (even on third-party sites) that may be of interest to the site visitor, in accordance with those preferences. Marketing cookies use the tools of external companies. These marketing cookies will only be used with your consent.",
                        toggle: {
                            value: 'tracking',
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            {
                                col1: '_fbp',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Facebook Pixel - displaying ads and passing information to Facebook',
                            },
                        ]
                    }*/
                    // , {
                    //     title: 'More information',
                    //     description: 'For any queries in relation to my policy on cookies and your choices, please <a class="cc-link" href="https://orestbida.com/contact">contact me</a>.',
                    // }
                ]
            }
        },
        'cs': {
            consent_modal: {
                title: "Informace o cookies",
                description: 'Kliknutím na&nbsp;tlačítko <q>Souhlasím se&nbsp;vším</q> se&nbsp;uloží technické, analytické a&nbsp;marketingové soubory cookie, abychom vám mohli umožnit pohodlné používání stránek, měřit funkčnost našich stránek a&nbsp;cílit na&nbsp;Vás reklamu. Své preference můžete snadno upravit kliknutím na&nbsp;tlačítko <q>Nastavení cookie</q>.',
                primary_btn: {
                    text: 'Souhlasím se vším',
                    role: 'accept_all'  //'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Nastavení cookies',
                    role: 'settings'   //'settings' or 'accept_necessary'
                },
                revision_message: '<br><br> Vážený uživateli, smluvní podmínky se od vaší poslední návštěvy změnily!'
            },
            settings_modal: {
                title: 'Nastavení cookies',
                save_settings_btn: "Uložit aktuální nastavení",
                accept_all_btn: "Souhlasím se vším",
                reject_all_btn: 'Odmítnout vše',
                close_btn_label: "Zavřít",
                cookie_table_headers: [
                    { col1: "Název cookie" },
                    { col2: "Doména" },
                    { col3: "Popis" },
                    //{col4: 'Expirace'}
                ],
                blocks: [
                    {
                        title: "Používáme cookies",
                        description: 'Soubory cookie používáme k&nbsp;analýze údajů o&nbsp;našich návštěvnících, ke&nbsp;zlepšení našich webových stránek, zobrazení personalizovaného obsahu a&nbsp;k&nbsp;tomu, abychom Vám poskytli skvělý zážitek z&nbsp;webu. Používáním tohoto webu s&nbsp;tímto používáním souhlasíte. Více viz <a href="zasady-ochrany-osobnich-udaju.html" class="cc-link">Zásady ochrany osobních údajů</a>.',
                    }, {
                        title: "Technické cookies",
                        description: 'Tyto soubory cookie jsou nezbytné pro&nbsp;fungování webových stránek, není tedy možné je zakázat. Obvykle se&nbsp;nastavují v&nbsp;reakci na&nbsp;akci, kterou na&nbsp;webu sami provedete, jako je nastavení zabezpečení, přihlášení a&nbsp;vyplňování formulářů. Svůj prohlížeč můžete nastavit tak, aby blokoval soubory cookie nebo o&nbsp;nich zasílal upozornění. Mějte na&nbsp;paměti, že&nbsp;některé stránky nebudou bez&nbsp;těchto souborů fungovat. Tyto soubory cookie neukládají žádné informace, které lze přiřadit konkrétní osobě. Tyto soubory cookie můžeme nastavit my, nebo poskytovatelé třetích stran, jejichž služby na&nbsp;webu využíváme. Tyto soubory cookie neukládají žádné informace, které lze přiřadit konkrétní osobě.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true  //cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            {
                                col1: 'cc_cookie',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Vaše nastavení cookies z tohoto dialogu',
                            }
                        ]
                    }, {
                        title: "Analytické cookies",
                        description: 'Tyto soubory cookie se&nbsp;používají ke&nbsp;zlepšení fungování webových stránek. Umožňují nám rozpoznat a&nbsp;sledovat počet návštěvníků a&nbsp;sledovat, jak návštěvníci web používají. Pomáhají nám zlepšovat způsob, jakým webové stránky fungují, například tím, že uživatelům umožňují snadno najít to, co hledají. Tyto soubory cookie neshromažďují informace, které by Vás mohly identifikovat. Pomocí těchto nástrojů analyzujeme a&nbsp;pravidelně zlepšujeme funkčnost našich webových stránek. Získané statistiky můžeme využít ke&nbsp;zlepšení uživatelského komfortu a&nbsp;k&nbsp;tomu, aby byla návštěva webu pro&nbsp;Vás jako uživatele zajímavější.',
                        toggle: {
                            value: 'analytics', // there are no default categories => you specify them
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'cc_youtube',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Cookie nastavené pluginem iframemanager'
                            },
                            {
                                col1: 'cc_googleMap',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Cookie nastavené pluginem iframemanager'
                            },
                            {
                                col1: '_pk_ses.7.c684',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Matomo Analytics - dočasné uložení údajů o&nbsp;návštěvě',
                            },
                            {
                                col1: '_pk_id.7.c684',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Matomo Analytics - jedinečné ID',
                            }/*,
                            {
                                col1: '_ga/_ga*, _gid',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Google Analytics - ukládá a&nbsp;počítá počet zobrazení stránek a&nbsp;chování webu',
                            },
                            {
                                col1: '_gcl_au',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Google Tag Manager - propojení konverzí',
                            },
                            {
                                col1: 'permId, p##*, pc##*',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Incomaker - ukládá a&nbsp;počítá zobrazení stránek a&nbsp;konverze',
                            },
                            {
                                col1: '_my_cookie',
                                col2: 'swo.vavyskov.cz',
                                col3: 'test cookie with custom path ...',
                                path: '/demo'       // needed for autoclear cookies
                            }*/
                        ]
                    }/*, {
                        title: "Marketingové cookies",
                        description: 'Používají se ke sledování preferencí webu uživatele za účelem cílení reklamy, tj. zobrazování marketingových a reklamních sdělení (i na stránkách třetích stran), které mohou návštěvníka webu zajímat, v souladu s těmito preferencemi. Marketingové cookies využívají nástroje externích společností. Tyto marketingové soubory cookie budou použity pouze s vaším souhlasem.',
                        toggle: {
                            value: 'tracking',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: '_fbp',
                                col2: 'swo.vavyskov.cz',
                                col3: 'Facebook Pixel -  zobrazování reklam a předávání informací do Facebooku',
                            },
                        ]
                    }*/
                    // , {
                    //     title: 'Více informací',
                    //     description: 'Vlastní text' + ' <a class="cc-link" href="https://orestbida.com/contact/">Kontaktujte nás</a>.',
                    // }
                ]
            }
        }
    }
});

/*if (!cc.validCookie('cc_cookie')) {
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
        event: "CookieConsent",
        consentType: "empty"
    });
}*/





//});
